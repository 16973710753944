import { CssBaseline } from '@material-ui/core';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './store';

ReactDOM.render(
	<CookiesProvider>
		<Provider store={store}>
			<BrowserRouter>
				<CssBaseline />
				<App />
			</BrowserRouter>
		</Provider>
	</CookiesProvider>,
	document.getElementById('root')
);
