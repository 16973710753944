import { ThemeProvider } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { logIn } from '../store/slices/sessionSlice';
import theme from '../styles/theme';
import { saveCookie } from '../utils/cookie';
import { login } from '../utils/requests';
import Login from './login';
import VpnHome from './vpnHome';

function VpnHub() {
	const history = useHistory();
	const session = useSelector((st) => st.session.value);
	const dispatch = useDispatch();
	const [cookies, setCookie] = useCookies(['SV_VPNMGR_SESSION']);
	const [routes] = useState([
		{
			path: '/login',
			component: <Login />,
		},
		{
			path: '/',
			component: <VpnHome />,
		},
	]);

	useEffect(() => {
		(async () => {
			if (session) {
				if (
					history.location.pathname === '/vpn/' ||
					history.location.pathname === '/email/' ||
					history.location.pathname === '/vpn/login'
				) {
					history.replace('/vpn/certificados');
				}
			} else {
				if (
					cookies['SV_VPNMGR_SESSION'] !== undefined &&
					cookies['SV_VPNMGR_SESSION'] !== null
				) {
					const r = await login({});
					if (r) {
						saveCookie({
							setCookie,
							info: r,
							dispatch,
							setValue: logIn,
						});
						if (
							history.location.pathname === '/vpn/' ||
							history.location.pathname === '/email/' ||
							history.location.pathname === '/vpn/login'
						) {
							history.replace('/vpn/certificados');
						}
					} else {
						setCookie('SV_VPNMGR_SESSION', null, {
							expires: new Date(),
							path: '/',
						});
						if (history.location.pathname !== '/vpn/login') {
							history.push('/vpn/login');
						}
					}
				} else {
					if (history.location.pathname !== '/vpn/login') {
						history.push('/vpn/login');
					}
				}
			}
		})();
	}, [session, history, cookies, dispatch, setCookie]);

	return (
		<ThemeProvider theme={theme}>
			<Switch>
				{routes.map((r) => (
					<Route path={'/vpn' + r.path} key={'/vpn' + r.path}>
						{r.component}
					</Route>
				))}
			</Switch>
		</ThemeProvider>
	);
}

export default VpnHub;
