import { Button, CircularProgress } from '@material-ui/core';
export default function SubmitButton({ loading, onSubmit, ...props }) {
	return (
		<Button
			disabled={loading}
			endIcon={loading && <CircularProgress size={20} color='primary' />}
			type='submit'
			onClick={onSubmit}
			{...props}
		/>
	);
}
