import { FolderIcon } from '@heroicons/react/outline';
import {
	Button,
	FormControl,
	makeStyles,
	SvgIcon,
	Typography
} from '@material-ui/core';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
	input: { display: 'none' },
	label: { width: '100%' },
	buttonSelect: {
		textTransform: 'none',
	},
	labelText: {
		marginBottom: theme.spacing(1),
	},
}));

export default function FileInput({ value, onChange, label }) {
	const [id] = useState(`file_input_${Math.floor(Math.random() * 1000000)}`);
	const classes = useStyles();

	return (
		<FormControl fullWidth>
			<Typography
				variant='body2'
				component='label'
				className={classes.labelText}
			>
				{label}
			</Typography>
			<input
				type='file'
				id={id}
				className={classes.input}
				onChange={(e) => {
					if (e.target.files.length > 0) {
						onChange(e.target.files[0]);
					} else {
						onChange(null);
					}
				}}
			/>
			<label htmlFor={id} className={classes.label}>
				<Button
					className={classes.buttonSelect}
					component='span'
					fullWidth
					color='secondary'
					variant='outlined'
					startIcon={
						value ? undefined : (
							<SvgIcon>
								<FolderIcon />
							</SvgIcon>
						)
					}
				>
					{value ? value.name : 'seleccionar archivo'}
				</Button>
			</label>
		</FormControl>
	);
}
