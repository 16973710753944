import { Grid, makeStyles, TextField } from '@material-ui/core';
import { useState } from 'react';
import { onChangeText } from '../../utils/listeners';
import { newClient } from '../../utils/requests';
import SubmitButton from '../formButton';

const useStyles = makeStyles((t) => ({
	form: {
		paddingTop: t.spacing(2),
		paddingRight: t.spacing(1),
		paddingLeft: t.spacing(1),
	},
}));

export default function ClientForm({ reload, onAlert }) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [nombre, setNombre] = useState('');

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const r = await newClient(nombre);

		if (r.Estado === 'ok') {
			onAlert('Cliente creado con éxito', 'success');
			setNombre('');
			reload();
		} else {
			onAlert(
				r.Descripcion
					? r.Descripcion
					: 'Ha ocurrido un error desconocido',
				'error'
			);
		}

		setLoading(false);
	};

	return (
		<form onSubmit={onSubmit}>
			<Grid
				direction='column'
				container
				className={classes.form}
				spacing={4}
			>
				<Grid item>
					<TextField
						label='Nombre'
						fullWidth
						color='secondary'
						onChange={onChangeText(setNombre)}
						value={nombre}
					/>
				</Grid>
				<Grid item container justifyContent='flex-end'>
					<SubmitButton color='primary' {...{ onSubmit, loading }}>
						crear
					</SubmitButton>
				</Grid>
			</Grid>
		</form>
	);
}
