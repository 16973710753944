import {
	Button,
	createTheme,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	lighten,
	makeStyles,
	ThemeProvider
} from '@material-ui/core';
import { brown, green, grey, red } from '@material-ui/core/colors';

const styledBy = (property, mapping) => (props) => {
	return mapping[props[property]];
};

const useStyles = makeStyles((t) => ({
	text: {
		fontWeight: 'bold',
		color: styledBy('color', {
			default: grey[500],
			success: green[700],
			error: red[400],
			info: brown[400],
		}),
	},
	paper: {
		borderColor: styledBy('color', {
			default: lighten(grey[500], 0.7),
			success: lighten(green[700], 0.7),
			error: lighten(red[400], 0.7),
			info: lighten(brown[400], 0.7),
		}),
	},
}));

const defaultTheme = {
	typography: {
		fontFamily: ['Kumbh Sans', 'Arial'].join(','),
		button: {
			textTransform: 'lowercase',
		},
	},
};

const defTheme = createTheme({
	palette: {
		primary: {
			main: grey[500],
		},
	},
	...defaultTheme,
});

const successTheme = createTheme({
	palette: {
		primary: {
			main: green[700],
		},
	},
	...defaultTheme,
});

const errorTheme = createTheme({
	palette: {
		primary: {
			main: red[400],
		},
	},
	...defaultTheme,
});

const infoTheme = createTheme({
	palette: {
		primary: {
			main: brown[400],
		},
	},
	...defaultTheme,
});

const themeVariant = (variant) => {
	switch (variant) {
		case 'success':
			return successTheme;
		case 'error':
			return errorTheme;
		case 'info':
			return infoTheme;
		default:
			return defTheme;
	}
};

export function Alert({ content, variant, onClose }) {
	const classes = useStyles({ color: variant ? variant : 'default' });

	return (
		<Dialog
			open={true}
			PaperProps={{
				className: classes.paper,
			}}
		>
			<DialogContent>
				<DialogContentText className={classes.text}>
					{content}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<ThemeProvider theme={themeVariant(variant)}>
					<Button color='primary' onClick={onClose}>
						entendido
					</Button>
				</ThemeProvider>
			</DialogActions>
		</Dialog>
	);
}

export function ConfirmAlert({ content, onClose, onConfirm }) {
	const classes = useStyles({ color: 'default' });

	return (
		<Dialog
			open={true}
			PaperProps={{
				className: classes.paper,
			}}
		>
			<DialogContent>
				<DialogContentText className={classes.text}>
					{content}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<ThemeProvider theme={themeVariant('default')}>
					<Button onClick={onClose}>cancelar</Button>
					<Button color='primary' onClick={onConfirm}>
						confirmar
					</Button>
				</ThemeProvider>
			</DialogActions>
		</Dialog>
	);
}
