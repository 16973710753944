import { useEffect, useState } from 'react';

export default function useSvLayout(listItems, intervalTime) {
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const [items, setItems] = useState([]);
	const [search, setSearch] = useState('');
	const [loadingItems, setLoadingItems] = useState(false);
	const [forceReload, setForceReload] = useState(false);
	const [alert, setAlert] = useState(null);
	const [confirmAlert, setConfirmAlert] = useState(null);
	const [client, setClient] = useState(null);

	useEffect(() => {
		let interval = setInterval(() => {
			listItems({
				setItems,
				page,
				setCount,
				search,
				setLoading: setLoadingItems,
				client,
			})();
		}, intervalTime ? intervalTime : 5000);
		return () => {
			clearInterval(interval);
		};
	}, [page, search, client]);

	useEffect(() => {
		listItems({
			setItems,
			page,
			setCount,
			search,
			setLoading: setLoadingItems,
			client,
		})();
	}, [forceReload, page, search, client]);

	const reload = (page, search) => {
		if (page) setPage(page);
		if (search !== undefined) setSearch(search);
		setForceReload(!forceReload);
	};

	const showConfirmAlert = (content, onConfirm) => {
		if (content) {
			setConfirmAlert({
				content,
				onConfirm,
				onClose: (e) => setConfirmAlert(null),
			});
		} else {
			setConfirmAlert(null);
		}
	};

	const showAlert = (content, variant) => {
		if (content) {
			setAlert({
				content,
				variant,
				onClose: (e) => setAlert(null),
			});
		} else {
			setAlert(null);
		}
	};

	return {
		page,
		setPage,
		count,
		setCount,
		items,
		setItems,
		loadingItems,
		setLoadingItems,
		reload,
		alert,
		confirmAlert,
		showAlert,
		showConfirmAlert,
		setClient,
		client,
	};
}
