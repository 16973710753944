import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import {
	Grid,
	IconButton,
	makeStyles,
	SvgIcon,
	TextField
} from '@material-ui/core';
import { useState } from 'react';
import { onChangeText } from '../../utils/listeners';
import { newUser } from '../../utils/requests';
import SubmitButton from '../formButton';

const useStyles = makeStyles((t) => ({
	form: {
		paddingTop: t.spacing(2),
		paddingRight: t.spacing(1),
		paddingLeft: t.spacing(1),
	},
}));

export default function UserForm({ reload, onAlert }) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [hidePassword, setHidePassword] = useState(true);
	const [nombre, setNombre] = useState('');
	const [usuario, setUsuario] = useState('');
	const [password, setPassword] = useState('');

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const r = await newUser(usuario, nombre, password);

		if (r.Estado === 'ok') {
			onAlert('Usuario creado con éxito', 'success');
			setUsuario('');
			setNombre('');
			setPassword('');
			reload();
		} else {
			onAlert(
				r.Descripcion
					? r.Descripcion
					: 'Ha ocurrido un error desconocido',
				'error'
			);
		}

		setLoading(false);
	};

	return (
		<form onSubmit={onSubmit}>
			<Grid
				direction='column'
				container
				className={classes.form}
				spacing={4}
			>
				<Grid item>
					<TextField
						label='Nombre'
						fullWidth
						color='secondary'
						value={nombre}
						onChange={onChangeText(setNombre)}
					/>
				</Grid>
				<Grid item>
					<TextField
						label='Usuario'
						fullWidth
						color='secondary'
						value={usuario}
						onChange={onChangeText(setUsuario)}
					/>
				</Grid>
				<Grid item>
					<TextField
						label='Contraseña'
						color='secondary'
						fullWidth
						value={password}
						onChange={onChangeText(setPassword)}
						type={hidePassword ? 'password' : 'text'}
						InputProps={{
							endAdornment: (
								<IconButton
									onClick={(e) =>
										setHidePassword(!hidePassword)
									}
									color='secondary'
								>
									<SvgIcon>
										{hidePassword ? (
											<EyeIcon />
										) : (
											<EyeOffIcon />
										)}
									</SvgIcon>
								</IconButton>
							),
						}}
					/>
				</Grid>
				<Grid item container justifyContent='flex-end'>
					<SubmitButton color='primary' {...{ onSubmit, loading }}>
						crear
					</SubmitButton>
				</Grid>
			</Grid>
		</form>
	);
}
