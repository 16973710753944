import { Button, Grid, TextField } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { onChangeText } from "../../utils/listeners";
import {
    addDomain,
    handleSimpleResponse,
    updateDomain
} from "../../utils/requests";
import { FormDialog } from "../dialog";

export default function DomainForm({ onAlert, defaultValue, onSubmitted }) {
    const [open, setOpen] = useState(false);
    const [domainName, setDomainName] = useState("");
    const [domainId, setDomainId] = useState("");

    const openModal = () => setOpen(true);
    const closeModal = () => {
        onSubmitted();
        setOpen(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const r = defaultValue
            ? await updateDomain(domainId, domainName)
            : await addDomain(domainName);

        handleSimpleResponse({
            response: r,
            alert: onAlert,
            success: defaultValue
                ? "Dominio editado con exito"
                : "Dominio creado con exito",
            error: "Ocurrio un error haciendo la peticion",
            handler: (r) => {
                setDomainName("");
                setDomainId("");

                closeModal();
            },
        });
    };

    useEffect(() => {
        if (defaultValue) {
            setDomainName(defaultValue.DomainName);
            setDomainId(defaultValue.DomainId);
            openModal();
        }
    }, [defaultValue]);

    return (
        <Fragment>
            {!defaultValue && (
                <Button
                    style={{ marginLeft: 8 }}
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={openModal}
                >
                    nuevo dominio
                </Button>
            )}
            <FormDialog
                open={open}
                title={defaultValue ? "Editar dominio" : "Nuevo dominio"}
                onClose={closeModal}
                onSubmit={onSubmit}
                disabled={domainName === ""}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            label="Nombre del dominio"
                            fullWidth
                            color="primary"
                            value={domainName}
                            onChange={onChangeText(setDomainName)}
                        />
                    </Grid>
                </Grid>
            </FormDialog>
        </Fragment>
    );
}
