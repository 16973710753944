import {
	LinearProgress,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	withStyles
} from '@material-ui/core';

const useStyles = makeStyles((t) => ({
	tableHeadCell: {
		fontWeight: 'bold',
		color: t.palette.primary.main,
	},
	tableContainer: {
		backgroundColor: 'transparent',
		paddingTop: t.spacing(1),
		paddingBottom: t.spacing(1),
		paddingLeft: t.spacing(2),
	},
	noData: {
		fontWeight: 'bold',
	},
}));

const SvTableRow = withStyles((t) => ({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
		'&:hover': {
			backgroundColor: t.palette.tableHover.main,
		},
	},
}))(TableRow);

const SvTableCell = withStyles((t) => ({
	root: {
		padding: t.spacing(1),
	},
}))(TableCell);

function SvTableHead({ items, prependColumn, options }) {
	const classes = useStyles();

	return (
		<TableHead>
			<SvTableRow>
				{prependColumn && (
					<SvTableCell
						className={classes.tableHeadCell}
						style={{ width: 0 }}
					></SvTableCell>
				)}
				{items.map((i) => (
					<SvTableCell key={i.key} className={classes.tableHeadCell}>
						{i.label}
					</SvTableCell>
				))}
				{options && (
					<SvTableCell
						className={classes.tableHeadCell}
						align='center'
						style={{
							paddingRight: 16,
							width: '15%',
						}}
					>
						Opciones
					</SvTableCell>
				)}
			</SvTableRow>
		</TableHead>
	);
}

export default function SvTable({
	headers,
	items,
	prependColumn,
	options,
	loading,
}) {
	const classes = useStyles();

	return (
		<TableContainer className={classes.tableContainer}>
			{loading && <LinearProgress color='primary' />}
			<Table>
				<SvTableHead
					items={headers}
					prependColumn={prependColumn !== undefined}
					options={options !== undefined}
				/>
				<TableBody>
					{items.length === 0 && (
						<SvTableRow>
							<SvTableCell
								colSpan={
									headers.length +
									(options ? 1 : 0) +
									(prependColumn ? 1 : 0)
								}
								align='center'
								className={classes.noData}
							>
								No hay información que mostrar
							</SvTableCell>
						</SvTableRow>
					)}
					{items.map((i) => {
						return (
							<SvTableRow key={i._id ? i._id : JSON.stringify(i)}>
								{prependColumn && (
									<SvTableCell>
										{prependColumn(i)}
									</SvTableCell>
								)}
								{headers.map((h) => (
									<SvTableCell key={i[h.key]}>
										{i[h.key]}
									</SvTableCell>
								))}
								{options && (
									<SvTableCell align='center'>
										{options(i)}
									</SvTableCell>
								)}
							</SvTableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
