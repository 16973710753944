import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
	name: 'session',
	initialState: {
		value: null,
	},
	reducers: {
		logIn: (state, action) => {
			state.value = action.payload;
		},
		logOut: (state) => {
			state.value = null;
		},
	},
});

export const { logIn, logOut } = sessionSlice.actions;

const sessionReducer = sessionSlice.reducer;
export default sessionReducer;
