import { MenuAlt4Icon } from "@heroicons/react/solid";
import {
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    SvgIcon,
    ThemeProvider,
    Typography
} from "@material-ui/core";
import { useState } from "react";
import theme, { emailTheme } from "../styles/theme";
import SvLink from "./link";

const useStyles = makeStyles((t) => ({
    button: {
        marginRight: t.spacing(2),
    },
    title: {
        fontWeight: "bold",
    },
    paper: {
        backgroundColor: t.palette.appBar.main,
        minWidth: 200,
    },
}));

export default function MenuButton() {
    const classes = useStyles();
    const [anchor, setAnchor] = useState(null);

    const openMenu = (e) => setAnchor(e.currentTarget);
    const closeMenu = () => setAnchor(null);

    return (
        <>
            <IconButton
                size="large"
                edge="start"
                color="primary"
                onClick={openMenu}
                className={classes.button}
                disableFocusRipple
            >
                <SvgIcon>
                    <MenuAlt4Icon />
                </SvgIcon>
            </IconButton>
            <Menu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={closeMenu}
                PaperProps={{ className: classes.paper }}
            >
                <ThemeProvider theme={theme}>
                    <MenuItem component={SvLink} to="/vpn">
                        <Typography
                            color="primary"
                            variant="h6"
                            component="span"
                            className={classes.title}
                        >
                            vpn
                        </Typography>
                        <Typography variant="h6" component="span">
                            manager
                        </Typography>
                    </MenuItem>
                </ThemeProvider>
                <ThemeProvider theme={emailTheme}>
                    <MenuItem component={SvLink} to="/email">
                        <Typography
                            color="primary"
                            variant="h6"
                            component="span"
                            className={classes.title}
                        >
                            sisvox
                        </Typography>
                        <Typography variant="h6" component="span">
                            mail
                        </Typography>
                    </MenuItem>
                </ThemeProvider>
            </Menu>
        </>
    );
}
