import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SvAppBar from '../components/appbar';
import Certs from './certs';
import Clients from './clients';
import Passwords from './passwords';
import Users from './users';

const useStyles = makeStyles((t) => ({
	root: {
		display: 'flex',
	},
}));

function VpnHome() {
	const classes = useStyles();
	const [routes] = useState([
		{
			path: 'certificados',
			component: <Certs />,
		},
		{
			path: 'usuarios',
			component: <Users />,
		},
		{
			path: 'clientes',
			component: <Clients />,
		},
        {
            path: 'passwords/:certificado/:descripcion/:ip',
            component: <Passwords />
        }
	]);

	return (
		<div className={classes.root}>
			<SvAppBar />
			<Switch>
				<Route exact path={'/vpn/'}>
					<Redirect to={'/vpn/certificados'} />
				</Route>
				{routes.map((r) => (
					<Route path={'/vpn/' + r.path} key={'/vpn/' + r.path}>
						{r.component}
					</Route>
				))}
			</Switch>
		</div>
	);
}

export default VpnHome;
