import { Fragment } from 'react';
import { Alert, ConfirmAlert } from './alerts';

function Layout({ children, alert, confirmAlert }) {
	return (
		<Fragment>
			{alert && <Alert {...alert} />}
			{confirmAlert && <ConfirmAlert {...confirmAlert} />}
			{children}
		</Fragment>
	);
}

export default Layout;
