import {
    DownloadIcon,
    KeyIcon,
    LockClosedIcon,
    PencilIcon,
    StatusOfflineIcon,
    StatusOnlineIcon,
    TrashIcon
} from "@heroicons/react/outline";
import { IconButton, SvgIcon, ThemeProvider } from "@material-ui/core";
import {
    blue,
    deepPurple,
    green,
    indigo,
    red,
    teal
} from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

const defaultTheme = {
    typography: {
        fontFamily: ["Kumbh Sans", "Arial"].join(","),
        button: {
            textTransform: "lowercase",
        },
    },
};

export function DeleteButton(props) {
    return (
        <ThemeProvider
            theme={createTheme({
                ...defaultTheme,
                palette: {
                    primary: {
                        main: red[400],
                        contrastText: "#FFFFFF",
                    },
                },
            })}
        >
            <IconButton color="primary" size="small" {...props}>
                <SvgIcon>
                    <TrashIcon />
                </SvgIcon>
            </IconButton>
        </ThemeProvider>
    );
}

export function EditButton(props) {
    return (
        <ThemeProvider
            theme={createTheme({
                ...defaultTheme,
                palette: {
                    primary: {
                        main: blue[800],
                        contrastText: "#FFFFFF",
                    },
                },
            })}
        >
            <IconButton color="primary" size="small" {...props}>
                <SvgIcon>
                    <PencilIcon />
                </SvgIcon>
            </IconButton>
        </ThemeProvider>
    );
}

export function EditPasswordButton(props) {
    return (
        <ThemeProvider
            theme={createTheme({
                ...defaultTheme,
                palette: {
                    primary: {
                        main: deepPurple.A400,
                        contrastText: "#FFFFFF",
                    },
                },
            })}
        >
            <IconButton color="primary" size="small" {...props}>
                <SvgIcon>
                    <KeyIcon />
                </SvgIcon>
            </IconButton>
        </ThemeProvider>
    );
}

export function DownloadButton(props) {
    return (
        <ThemeProvider
            theme={createTheme({
                ...defaultTheme,
                palette: {
                    primary: {
                        main: green[700],
                        contrastText: "#FFFFFF",
                    },
                },
            })}
        >
            <IconButton color="primary" size="small" {...props}>
                <SvgIcon>
                    <DownloadIcon />
                </SvgIcon>
            </IconButton>
        </ThemeProvider>
    );
}

export function StatusButton({ isOn, ...props }) {
    return (
        <ThemeProvider
            theme={createTheme({
                ...defaultTheme,
                palette: {
                    primary: {
                        main: isOn ? teal.A700 : red[200],
                        contrastText: "#FFFFFF",
                    },
                },
            })}
        >
            <IconButton color="primary" size="small" {...props}>
                <SvgIcon>
                    {isOn ? <StatusOnlineIcon /> : <StatusOfflineIcon />}
                </SvgIcon>
            </IconButton>
        </ThemeProvider>
    );
}

export function PasswordsButton(props) {
    return (
        <ThemeProvider
            theme={createTheme({
                ...defaultTheme,
                palette: {
                    primary: {
                        main: indigo.A700,
                        contrastText: "#FFFFFF",
                    },
                },
            })}
        >
            <IconButton color="primary" size="small" {...props}>
                <SvgIcon>
                    <LockClosedIcon />
                </SvgIcon>
            </IconButton>
        </ThemeProvider>
    );
}
