import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from "@heroicons/react/solid";
import {
    Button,
    Grid,
    IconButton,
    makeStyles,
    SvgIcon,
    Typography
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0.8),
    },
    cols: {
        paddingRight: theme.spacing(1),
    },
    button: {
        paddingLeft: theme.spacing(0.8),
        paddingRight: theme.spacing(0.8),
        minWidth: "auto",
        fontWeight: "bold",
    },
}));

function PaginationIconButton({ children, ...props }) {
    return (
        <IconButton size="small" {...props}>
            <SvgIcon>{children}</SvgIcon>
        </IconButton>
    );
}

function PaginationTextButton({ children, ...props }) {
    const classes = useStyles();

    return (
        <Button {...props} size="small" className={classes.button}>
            {children}
        </Button>
    );
}

export default function SvPagination({
    page,
    count,
    onPageChange,
    children,
    rowsPerPage,
    ...props
}) {
    const classes = useStyles();
    const frowsPerPage = rowsPerPage ? rowsPerPage : 15;

    let initialNumber = count === 0 ? 0 : (page - 1) * frowsPerPage + 1;
    let finalNumber = page * frowsPerPage;
    let prev = [];
    let next = [];

    if (finalNumber > count) {
        finalNumber = count;
    }

    let numPages = Math.floor(count / frowsPerPage) + 1;
    if (count !== 0 && count % frowsPerPage === 0) {
        numPages--;
    }

    for (let i = page - 3; i < page; i++) {
        if (i > 0) {
            prev.push(i);
        }
    }

    for (let i = page + 1; i <= page + 3; i++) {
        if (i <= numPages) {
            next.push(i);
        }
    }

    return (
        <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
            className={classes.root}
        >
            <Grid item className={classes.cols}>
                {children}
            </Grid>
            <Grid item className={classes.cols}>
                <Typography variant="body2">
                    {initialNumber} - {finalNumber} de {count}
                </Typography>
            </Grid>
            <Grid item className={classes.cols}>
                <PaginationIconButton
                    disabled={page === 1}
                    color="secondary"
                    onClick={(e) => onPageChange(1)}
                >
                    <ChevronDoubleLeftIcon />
                </PaginationIconButton>
                <PaginationIconButton
                    disabled={page === 1}
                    color="secondary"
                    onClick={(e) => onPageChange(page - 1)}
                >
                    <ChevronLeftIcon />
                </PaginationIconButton>
                {page - 3 > 1 && (
                    <PaginationTextButton disabled color="primary">
                        ...
                    </PaginationTextButton>
                )}
                {prev.map((i) => (
                    <PaginationTextButton
                        key={i}
                        onClick={(e) => onPageChange(i)}
                        color="primary"
                    >
                        {i}
                    </PaginationTextButton>
                ))}
                <PaginationTextButton disabled color="primary">
                    {page}
                </PaginationTextButton>
                {next.map((i) => (
                    <PaginationTextButton
                        key={i}
                        color="primary"
                        onClick={(e) => onPageChange(i)}
                    >
                        {i}
                    </PaginationTextButton>
                ))}
                {page + 3 < numPages && (
                    <PaginationTextButton disabled color="primary">
                        ...
                    </PaginationTextButton>
                )}
                <PaginationIconButton
                    disabled={page === numPages}
                    color="secondary"
                    onClick={(e) => onPageChange(page + 1)}
                >
                    <ChevronRightIcon />
                </PaginationIconButton>
                <PaginationIconButton
                    disabled={page === numPages}
                    color="secondary"
                    onClick={(e) => onPageChange(numPages)}
                >
                    <ChevronDoubleRightIcon />
                </PaginationIconButton>
            </Grid>
        </Grid>
    );
}
