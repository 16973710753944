import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export default function SvSelect({ value, label, options, onChange, ...props }) {
	return (
		<FormControl fullWidth>
			<InputLabel>{label}</InputLabel>
			<Select value={value} onChange={onChange} color='secondary' {...props}>
				{options.map((v) => (
					<MenuItem value={v.value}>{v.label}</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
