import {
    Button,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    makeStyles,
    Typography
} from "@material-ui/core";
import SubmitButton from "./formButton";

const useStyles = makeStyles((t) => ({
    text: {
        fontWeight: "bold",
        color: t.palette.secondary.main,
    },
    paper: {
        borderColor: t.palette.secondary.main,
        width: 300,
    },
}));

export function FormDialog({
    children,
    onClose,
    onSubmit,
    confirm,
    title,
    open,
    loading,
    disabled,
}) {
    const classes = useStyles();

    return (
        <MuiDialog
            open={open}
            PaperProps={{
                className: classes.paper,
            }}
        >
            <form onSubmit={onSubmit}>
                <DialogContent>
                    <Typography className={classes.text} variant="body2">
                        {title}
                    </Typography>
                </DialogContent>
                <DialogContent>{children}</DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>cerrar</Button>
                    <SubmitButton
                        color="secondary"
                        onSubmit={onSubmit}
                        loading={loading}
                        disabled={disabled}
                    >
                        {confirm ? confirm : "enviar"}
                    </SubmitButton>
                </DialogActions>
            </form>
        </MuiDialog>
    );
}
