import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import SvAppBar from "../components/emails/appbar";
import { logIn } from "../store/slices/sessionSlice";
import { saveCookie } from "../utils/cookie";
import { login } from "../utils/requests";
import Aliases from "./aliases";
import Domains from "./domains";
import Mails from "./mails";


const useStyles = makeStyles((t) => ({
    root: {
        display: "flex",
    },
}));

function EmailHome() {
    const classes = useStyles();

    const history = useHistory();
    const session = useSelector((st) => st.session.value);
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(["SV_VPNMGR_SESSION"]);

    const [routes] = useState([
        {
            path: "correos",
            component: <Mails />,
        },
        {
            path: "dominios",
            component: <Domains />,
        },
        {
            path: "alias",
            component: <Aliases />,
        },
    ]);

    useEffect(() => {
        (async () => {
            if (session) {
                if (
                    history.location.pathname === "/vpn/" ||
                    history.location.pathname === "/email/" ||
                    history.location.pathname === "/vpn/login"
                ) {
                    history.replace("/email/correos");
                }
            } else {
                if (
                    cookies["SV_VPNMGR_SESSION"] !== undefined &&
                    cookies["SV_VPNMGR_SESSION"] !== null
                ) {
                    const r = await login({});
                    if (r) {
                        saveCookie({
                            setCookie,
                            info: r,
                            dispatch,
                            setValue: logIn,
                        });
                        if (
                            history.location.pathname === "/vpn/" ||
                            history.location.pathname === "/email/" ||
                            history.location.pathname === "/vpn/login"
                        ) {
                            history.replace("/email/correos");
                        }
                    } else {
                        setCookie("SV_VPNMGR_SESSION", null, {
                            expires: new Date(),
                            path: "/",
                        });
                        if (history.location.pathname !== "/vpn/login") {
                            history.push("/vpn/login");
                        }
                    }
                } else {
                    if (history.location.pathname !== "/vpn/login") {
                        history.push("/vpn/login");
                    }
                }
            }
        })();
    }, [session, history, cookies, dispatch, setCookie]);

    return (
        <div className={classes.root}>
            <SvAppBar />
            <Switch>
                <Route exact path={"/email/"}>
                    <Redirect to={"/email/correos"} />
                </Route>
                {routes.map((r) => (
                    <Route path={"/email/" + r.path} key={"/email/" + r.path}>
                        {r.component}
                    </Route>
                ))}
            </Switch>
        </div>
    );
}

export default EmailHome;
