import { Fragment } from "react";
import { DeleteButton } from "../components/buttons";
import PasswordForm from "../components/forms/mail/password";
import MailForm from "../components/forms/mails";
import Layout from "../components/layout";
import SvMain from "../components/main";
import SvPagination from "../components/pagination";
import SvTable from "../components/table";
import { SvSearchField } from "../components/textField";
import { deleteMail, handleSimpleResponse, listMails } from "../utils/requests";
import useSvLayout from "../utils/useLayout";

const headers = [
    { label: "Dominio", key: "DomainName" },
    { label: "Correo", key: "Email" },
];

const listItems = ({ setItems, setCount, page, search }) => {
    return async () => {
        const r = await listMails({ pagina: page, text: search });

        if (r.Estado === "ok") {
            setItems(r.Registros);
            setCount(r.Cant);
        } else {
            setItems([]);
            setCount(0);
        }
    };
};

function Mails() {
    const {
        page,
        setPage,
        count,
        items,
        loadingItems,
        reload,
        alert,
        confirmAlert,
        showAlert,
        showConfirmAlert,
    } = useSvLayout(listItems, 30000);

    const onDelete = (i) => async (e) => {
        const r = await deleteMail(i.AccountId);
        handleSimpleResponse({
            success: "Correo eliminado con exito",
            error: "Hubo un problema eliminando el correo",
            response: r,
            alert: showAlert,
            reload,
            handler: (r) => {
                showConfirmAlert();
            },
        });
    };

    return (
        <Layout alert={alert} confirmAlert={confirmAlert}>
            <SvMain>
                <SvPagination
                    count={count}
                    page={page}
                    onPageChange={setPage}
                    rowsPerPage={10}
                >
                    <SvSearchField reloadFn={reload} />
                    <MailForm
                        onAlert={showAlert}
                        onSubmitted={() => reload()}
                    />
                </SvPagination>
                <SvTable
                    headers={headers}
                    items={items}
                    loading={loadingItems}
                    options={(i) => {
                        return (
                            <Fragment>
                                <PasswordForm
                                    mail={i}
                                    onSubmitted={() => reload()}
                                />
                                <DeleteButton
                                    onClick={(e) => {
                                        showConfirmAlert(
                                            "Si continuas se eliminara el correo " +
                                                i.Email,
                                            onDelete(i)
                                        );
                                    }}
                                />
                            </Fragment>
                        );
                    }}
                />
            </SvMain>
        </Layout>
    );
}

export default Mails;
