import { SearchIcon } from '@heroicons/react/outline';
import { lighten, makeStyles, SvgIcon, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { onChangeText } from '../utils/listeners';

const useStyles = makeStyles((t) => ({
	input: {
		minWidth: '20vw',
		backgroundColor: lighten(t.palette.secondary.main, 0.9),
		paddingLeft: t.spacing(2),
		paddingRight: t.spacing(2),
		borderRadius: 8,
		'& input::placeholder': {
			color: t.palette.secondary.main,
			fontWeight: 'bold',
		},
	},
	icon: {
		marginRight: t.spacing(1),
	},
}));

export function SvSearchField({ reloadFn, ...props }) {
	const classes = useStyles();
	const [value, setValue] = useState('');

	useEffect(() => {
		let timeout = setTimeout(() => {
			reloadFn(1, value);
		}, 800);
		return () => {
			clearTimeout(timeout);
		};
	}, [value]);

	return (
		<TextField
			placeholder='Buscar'
			InputProps={{
				disableUnderline: true,
				startAdornment: (
					<SvgIcon
						fontSize='small'
						color='secondary'
						className={classes.icon}
					>
						<SearchIcon />
					</SvgIcon>
				),
			}}
			classes={{ root: classes.input }}
			value={value}
			onChange={onChangeText(setValue)}
			{...props}
		/>
	);
}
