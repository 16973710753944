import { Redirect, Route, Switch } from 'react-router-dom';
import EmailHub from './pages/email';
import VpnHub from './pages/vpn';

function App() {
	return (
		<Switch>
			<Route exact path='/'>
				<Redirect to='/vpn/' />
			</Route>
			<Route path='/vpn'>
				<VpnHub />
			</Route>
			<Route path='/email'>
				<EmailHub />
			</Route>
		</Switch>
	);
}

export default App;
