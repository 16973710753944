import { Grid, makeStyles, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { onChangeText } from "../../utils/listeners";
import { editPassword, newPassword } from "../../utils/requests";
import SubmitButton from "../formButton";
import PasswordField from "../passwordField";

const useStyles = makeStyles((t) => ({
    form: {
        paddingTop: t.spacing(2),
        paddingRight: t.spacing(1),
        paddingLeft: t.spacing(1),
    },
}));

export default function PasswordsForm({
    reload,
    onAlert,
    onEdited,
    valueEdit,
    certificado
}) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [servicio, setServicio] = useState("");
    const [usuario, setUsuario] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        if (valueEdit !== null) {
            const { servicio, usuario, password } = valueEdit;
            setServicio(servicio);
            setUsuario(usuario);
            setPassword(password);
        }
    }, [valueEdit]);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (valueEdit) {
            const r = await editPassword(
                servicio,
                usuario,
                password,
                valueEdit._id,
                certificado
            );

            if (r.Estado === "ok") {
                onAlert("Contraseña editada con éxito", "success");
                setServicio("");
                setUsuario("");
                setPassword("");
                reload();
                onEdited();
            } else {
                onAlert(
                    r.Descripcion
                        ? r.Descripcion
                        : "Ha ocurrido un error desconocido",
                    "error"
                );
            }
        } else {
            const r = await newPassword(servicio, usuario, password, certificado);

            if (r.Estado === "ok") {
                onAlert("Contraseña creada con éxito", "success");
                setServicio("");
                setUsuario("");
                setPassword("");
                reload();
            } else {
                onAlert(
                    r.Descripcion
                        ? r.Descripcion
                        : "Ha ocurrido un error desconocido",
                    "error"
                );
            }
        }

        setLoading(false);
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid
                direction="column"
                container
                className={classes.form}
                spacing={4}
            >
                <Grid item>
                    <TextField
                        label="Servicio"
                        fullWidth
                        color="secondary"
                        onChange={onChangeText(setServicio)}
                        value={servicio}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="Usuario"
                        fullWidth
                        color="secondary"
                        onChange={onChangeText(setUsuario)}
                        value={usuario}
                    />
                </Grid>
                <Grid item>
                    <PasswordField
                        label="Contraseña"
                        onChange={onChangeText(setPassword)}
                        value={password}
                    />
                </Grid>
                <Grid item container justifyContent="flex-end">
                    <SubmitButton color="primary" {...{ onSubmit, loading }}>
                        {valueEdit ? "editar" : "crear"}
                    </SubmitButton>
                </Grid>
            </Grid>
        </form>
    );
}
