import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { SvgIcon, TextField } from '@material-ui/core';
import { useState } from 'react';

export default function PasswordField(props) {
	const [hide, setHide] = useState(true);
	return (
		<TextField
			color='secondary'
			fullWidth
			type={hide ? 'password' : 'text'}
			InputProps={{
				endAdornment: (
					<SvgIcon
						fontSize='small'
						onClick={(e) => setHide(!hide)}
						color='secondary'
						style={{ cursor: 'pointer' }}
					>
						{hide ? <EyeIcon /> : <EyeOffIcon />}
					</SvgIcon>
				),
			}}
			{...props}
		/>
	);
}
