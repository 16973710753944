import { Switch } from '@material-ui/core';
import { DeleteButton } from '../components/buttons';
import SvDrawer from '../components/drawer';
import UserForm from '../components/forms/users';
import Layout from '../components/layout';
import SvLightTooltip from '../components/lightTooltip';
import SvMain from '../components/main';
import SvPagination from '../components/pagination';
import SvTable from '../components/table';
import { SvSearchField } from '../components/textField';
import { deleteUser, disableUser, listUsers } from '../utils/requests';
import useSvLayout from '../utils/useLayout';

const headers = [
	{ label: 'Usuario', key: 'usuario' },
	{ label: 'Nombre', key: 'nombre' },
];

const listItems = ({ setItems, setCount, page, search }) => {
	return async () => {
		const r = await listUsers(page, search);
		if (r.Estado === 'ok') {
			setItems(r.Registros);
			setCount(r.Cuenta);
		} else {
			setItems([]);
			setCount(0);
		}
	};
};

function Users() {
	const {
		page,
		setPage,
		count,
		items,
		loadingItems,
		reload,
		alert,
		showAlert,
		confirmAlert,
		showConfirmAlert,
	} = useSvLayout(listItems);

	const onDelete = (i) => async (e) => {
		const r = await deleteUser(i.usuario, i._id);
		showConfirmAlert();
		if (r.Estado === 'ok') {
			showAlert('Usuario eliminado con éxito', 'success');
			reload();
		} else {
			showAlert(
				r.Descripcion
					? r.Descripcion
					: 'Ha ocurrido un error desconocido',
				'error'
			);
		}
	};

	const onDisable = (i) => async (e) => {
		const r = await disableUser(i.usuario, i._id);
		if (r.Estado !== 'ok') {
			showAlert(
				r.Descripcion
					? r.Descripcion
					: 'Ha ocurrido un error desconocido',
				'error'
			);
		} else {
			reload();
		}
	};

	return (
		<Layout alert={alert} confirmAlert={confirmAlert}>
			<SvDrawer>
				<UserForm reload={reload} onAlert={showAlert} />
			</SvDrawer>
			<SvMain>
				<SvPagination count={count} page={page} onPageChange={setPage}>
					<SvSearchField reloadFn={reload} />
				</SvPagination>
				<SvTable
					headers={headers}
					options={(i) => {
						return (
							<DeleteButton
								onClick={(e) => {
									showConfirmAlert(
										'Esta acción eliminará permanentemente el usuario ' +
											i.nombre,
										onDelete(i)
									);
								}}
							/>
						);
					}}
					prependColumn={(i) => {
						return (
							<SvLightTooltip
								title={i.activo ? 'Inhabilitar' : 'Habilitar'}
							>
								<Switch
									checked={i.activo}
									color='secondary'
									onClick={onDisable(i)}
								/>
							</SvLightTooltip>
						);
					}}
					items={items}
					loading={loadingItems}
				/>
			</SvMain>
		</Layout>
	);
}

export default Users;
