import { Button, Grid, TextField } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { onChangeText } from "../../utils/listeners";
import {
    addMail,
    handleSimpleResponse,
    selectDomains
} from "../../utils/requests";
import { FormDialog } from "../dialog";
import PasswordField from "../passwordField";
import SvSelect from "../select";

export default function MailForm({ onAlert, onSubmitted }) {
    const [open, setOpen] = useState(false);

    const [domains, setDomains] = useState([]);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [domainId, setDomainId] = useState("");

    const openModal = () => setOpen(true);
    const closeModal = () => {
        setOpen(false);
        onSubmitted();
    };

    const handleBlur = (e) => {
        if (email !== "" && email.indexOf("@") === -1) {
            setEmail(email + "@sisvox.com.mx");
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const r = await addMail({ DomainId: domainId, Email: email, password });

        handleSimpleResponse({
            response: r,
            alert: onAlert,
            success: "Correo creado con exito",
            error: "Ocurrio un error haciendo la peticion",
            handler: (r) => {
                setDomainId("");
                setEmail("");
                setPassword("");

                closeModal();
            },
        });
    };

    useEffect(() => {
        if (open) {
            (async () => {
                const rdomains = await selectDomains();
                handleSimpleResponse({
                    response: rdomains,
                    error: "Hubo un error consultando los dominios",
                    alert: onAlert,
                    handler: (r) => {
                        setDomains(
                            r.Registros.map((v) => ({
                                label: v.DomainName,
                                value: v.DomainId,
                            }))
                        );
                    },
                });
            })();
        }
    }, [open]);

    return (
        <Fragment>
            <Button
                style={{ marginLeft: 8 }}
                variant="contained"
                color="secondary"
                size="small"
                onClick={openModal}
            >
                nuevo correo
            </Button>
            <FormDialog
                open={open}
                title="Nuevo correo"
                onClose={closeModal}
                onSubmit={onSubmit}
                disabled={
                    email === "" ||
                    domainId === "" ||
                    password === "" ||
                    password !== confirmPassword
                }
            >
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Correo"
                            fullWidth
                            color="secondary"
                            value={email}
                            onChange={onChangeText(setEmail)}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordField
                            label="Contraseña"
                            value={password}
                            onChange={onChangeText(setPassword)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordField
                            label="Confirmar contraseña"
                            value={confirmPassword}
                            onChange={onChangeText(setConfirmPassword)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SvSelect
                            label="Dominio"
                            options={domains}
                            value={domainId}
                            onChange={onChangeText(setDomainId)}
                        />
                    </Grid>
                </Grid>
            </FormDialog>
        </Fragment>
    );
}
