import { useState } from "react";
import { DeleteButton, EditButton } from "../components/buttons";
import DomainForm from "../components/forms/domains";
import Layout from "../components/layout";
import SvMain from "../components/main";
import SvPagination from "../components/pagination";
import SvTable from "../components/table";
import { SvSearchField } from "../components/textField";
import {
    deleteDomain,
    handleSimpleResponse,
    listDomains
} from "../utils/requests";
import useSvLayout from "../utils/useLayout";

const headers = [
    { label: "Id", key: "DomainId" },
    { label: "Nombre", key: "DomainName" },
];

const listItems = ({ setItems, setCount, page, search }) => {
    return async () => {
        const r = await listDomains({ pagina: page, text: search });

        if (r.Estado === "ok") {
            setItems(r.Registros);
            setCount(r.Cant);
        } else {
            setItems([]);
            setCount(0);
        }
    };
};

function Domains() {
    const {
        page,
        setPage,
        count,
        items,
        loadingItems,
        reload,
        alert,
        showAlert,
        confirmAlert,
        showConfirmAlert,
    } = useSvLayout(listItems, 30000);
    const [item, setItem] = useState(null);

    const onSubmitted = () => {
        setItem(null);
        reload();
    };

    const onDelete = (i) => async (e) => {
        const r = await deleteDomain(i.DomainId);
        handleSimpleResponse({
            success: "Dominio eliminado con exito",
            error: "Hubo un problema eliminando el dominio",
            response: r,
            alert: showAlert,
            reload,
            handler: (r) => {
                showConfirmAlert();
            },
        });
    };

    return (
        <Layout alert={alert} confirmAlert={confirmAlert}>
            <SvMain>
                <SvPagination
                    count={count}
                    page={page}
                    onPageChange={setPage}
                    rowsPerPage={10}
                >
                    <SvSearchField reloadFn={reload} />
                    <DomainForm
                        onAlert={showAlert}
                        defaultValue={item}
                        onSubmitted={onSubmitted}
                    />
                </SvPagination>
                <SvTable
                    headers={headers}
                    items={items}
                    loading={loadingItems}
                    options={(i) => {
                        return (
                            <>
                                <EditButton onClick={(_) => setItem(i)} />
                                <DeleteButton
                                    onClick={(e) => {
                                        showConfirmAlert(
                                            "Si continuas se eliminara el dominio " +
                                                i.DomainName,
                                            onDelete(i)
                                        );
                                    }}
                                />
                            </>
                        );
                    }}
                />
            </SvMain>
        </Layout>
    );
}

export default Domains;
