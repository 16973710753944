import { Grid, TextField } from "@material-ui/core";
import { Fragment, useState } from "react";
import { onChangeText } from "../../../utils/listeners";
import { handleSimpleResponse, updatePassword } from "../../../utils/requests";
import { EditPasswordButton } from "../../buttons";
import { FormDialog } from "../../dialog";
import PasswordField from "../../passwordField";

export default function PasswordForm({ onAlert, mail, onSubmitted }) {
    const [open, setOpen] = useState(false);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const openModal = () => setOpen(true);
    const closeModal = () => {
        onSubmitted();
        setOpen(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const r = await updatePassword(mail.AccountId, password);

        handleSimpleResponse({
            response: r,
            alert: onAlert,
            success: "Contraseña cambiada con exito",
            error: "Ocurrio un error haciendo la peticion",
            handler: (r) => {
                setPassword("");
                setConfirmPassword("");

                closeModal();
            },
        });
    };

    return (
        <Fragment>
            <EditPasswordButton onClick={openModal} />
            <FormDialog
                open={open}
                title="Cambio de contraseña"
                onClose={closeModal}
                onSubmit={onSubmit}
                disabled={password === "" || password !== confirmPassword}
            >
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Correo"
                            defaultValue={mail.Email}
                            color="primary"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordField
                            label="Contraseña"
                            value={password}
                            onChange={onChangeText(setPassword)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordField
                            label="Confirmar contraseña"
                            value={confirmPassword}
                            onChange={onChangeText(setConfirmPassword)}
                        />
                    </Grid>
                </Grid>
            </FormDialog>
        </Fragment>
    );
}
