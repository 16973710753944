import {
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { selectClients } from '../utils/requests';

const useStyles = makeStyles((t) => ({
	tituloFiltro: {
		fontWeight: 'bold',
	},
}));

export default function SvClientFilter({ value, onChange, reload }) {
	const [clients, setClients] = useState([]);
	const classes = useStyles();

	useEffect(() => {
		(async () => {
			const r = await selectClients();
			if (r.Estado === 'ok') {
				setClients(
					r.Registros.map((v) => ({
						label: v.nombre,
						value: v._id,
					}))
				);
			}
		})();
	}, []);

	const onClick = (value) => (e) => {
		onChange(value);
		setTimeout(() => reload(1), 400);
	};

	return (
		<List dense>
			<ListItem>
				<Typography
					variant='caption'
					color='textSecondary'
					className={classes.tituloFiltro}
				>
					Filtro de cliente
				</Typography>
			</ListItem>
			<ListItem button onClick={onClick(null)} disabled={value === null}>
				<ListItemText primary='Todos' />
			</ListItem>
			{clients.map((v) => (
				<ListItem
					button
					onClick={onClick(v.value)}
					disabled={value === v.value}
					key={v.value}
				>
					<ListItemText primary={v.label} />
				</ListItem>
			))}
		</List>
	);
}
