import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    IconButton,
    makeStyles,
    SvgIcon,
    TextField,
    Typography
} from '@material-ui/core';
import { Fragment, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Alert } from '../components/alerts';
import img from '../img/bg7.svg';
import { logIn } from '../store/slices/sessionSlice';
import shadows from '../styles/shadows';
import { saveCookie } from '../utils/cookie';
import { inputChange } from '../utils/input';
import { login } from '../utils/requests';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100vw',
		height: '100vh',
		overflow: 'hidden',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 0,
		padding: 0,
	},
	img: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		zIndex: -1,
		opacity: 0.6,
	},
	card: {
		background: 'white',
		boxShadow: shadows.xl,
		minWidth: '20vw',
		paddingRight: theme.spacing(3),
		paddingLeft: theme.spacing(3),
		paddingTop: theme.spacing(1),
	},
	vpn: {
		fontWeight: 'bolder',
	},
	spacer: {
		flexGrow: 1,
	},
	button: {
		fontWeight: 'bold',
	},
}));

function Login() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const [, setCookie] = useCookies(['SV_VPNMGR_SESSION']);

	const [alert, setAlert] = useState(null);
	const [hide, setHide] = useState(true);
	const [user, setUser] = useState('');
	const [password, setPassword] = useState('');

	const showAlert = (content, variant) => {
		if (content) {
			setAlert({
				content,
				variant,
				onClose: (e) => setAlert(null),
			});
		} else {
			setAlert(null);
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		const r = await login({
			usuario: user,
			password,
		});

		if (r) {
			saveCookie({ setCookie, info: r, dispatch, setValue: logIn });
			history.goBack();
		} else {
			showAlert('Usuario o contraseña incorrectos', 'error');
		}
	};

	return (
		<Fragment>
			<img src={img} className={classes.img} alt='background' />
			{alert && <Alert {...alert} />}
			<div className={classes.root}>
				<Card className={classes.card}>
					<form onSubmit={onSubmit}>
						<CardContent>
							<Typography
								variant='h4'
								color='primary'
								component='span'
								className={classes.vpn}
							>
								vpn
							</Typography>
							<Typography variant='h4' component='span'>
								manager
							</Typography>
						</CardContent>
						<CardContent>
							<Grid container direction='column' spacing={3}>
								<Grid item>
									<TextField
										label='Usuario'
										color='secondary'
										fullWidth
										onChange={inputChange(setUser)}
										value={user}
									/>
								</Grid>
								<Grid item>
									<TextField
										label='Contraseña'
										color='secondary'
										fullWidth
										type={hide ? 'password' : 'text'}
										InputProps={{
											endAdornment: (
												<IconButton
													size='small'
													disableRipple
													onClick={(e) =>
														setHide(!hide)
													}
													color='secondary'
												>
													<SvgIcon fontSize='small'>
														{hide ? (
															<EyeIcon />
														) : (
															<EyeOffIcon />
														)}
													</SvgIcon>
												</IconButton>
											),
										}}
										onChange={inputChange(setPassword)}
										value={password}
									/>
								</Grid>
							</Grid>
						</CardContent>
						<CardActions>
							<div className={classes.spacer}></div>
							<Button
								color='primary'
								onClick={onSubmit}
								type='submit'
								className={classes.button}
							>
								iniciar sesión
							</Button>
						</CardActions>
					</form>
				</Card>
			</div>
		</Fragment>
	);
}

export default Login;
