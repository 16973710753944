export function saveCookie({ setCookie, info, dispatch, setValue }) {
	setCookie('SV_VPNMGR_SESSION', info, {
		expires: (() => {
			const d = new Date();
			d.setDate(d.getDate() + 1);
			return d;
		})(),
		path: '/',
	});
	dispatch(setValue(info));
}
