import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
    DeleteButton,
    DownloadButton,
    PasswordsButton,
    StatusButton
} from "../components/buttons";
import SvClientFilter from "../components/clientFilter";
import SvDrawer from "../components/drawer";
import CertForm from "../components/forms/certs";
import Layout from "../components/layout";
import SvMain from "../components/main";
import SvPagination from "../components/pagination";
import SvTable from "../components/table";
import { SvSearchField } from "../components/textField";
import { deleteCert, listCerts } from "../utils/requests";
import useSvLayout from "../utils/useLayout";

const headers = [
    { label: "IP", key: "ip" },
    { label: "Descripcion", key: "descripcion" },
    { label: "Cliente", key: "nombreCliente" },
    { label: "Creación", key: "fechaCreacion" },
    { label: "Última Conexión", key: "fechaUltimaConexion" },
    { label: "Sistema Operativo", key: "so" },
];

const listItems = ({ setItems, setCount, page, client, search }) => {
    return async () => {
        const r = await listCerts(page, search, client);
        if (r.Estado === "ok") {
            setItems(
                r.Registros.map((v) => ({
                    ...v,
                    fechaCreacion: v.fechaCreacion
                        ? new Date(v.fechaCreacion).toLocaleString()
                        : "",
                    fechaUltimaConexion: v.fechaUltimaConexion
                        ? new Date(v.fechaUltimaConexion).toLocaleString()
                        : "",
                }))
            );
            setCount(r.Cuenta);
        } else {
            setItems([]);
            setCount(0);
        }
    };
};

function Certs() {
    const history = useHistory();
    const {
        page,
        setPage,
        count,
        items,
        loadingItems,
        reload,
        alert,
        showAlert,
        confirmAlert,
        showConfirmAlert,
        client,
        setClient,
    } = useSvLayout(listItems);

    const onDelete = (i) => async (e) => {
        const r = await deleteCert(i._id);
        showConfirmAlert();
        if (r.Estado === "ok") {
            showAlert("Certificado eliminado con éxito", "success");
            reload();
        } else {
            showAlert(
                r.Descripcion
                    ? r.Descripcion
                    : "Ha ocurrido un error desconocido",
                "error"
            );
        }
    };

    const onDownload = (i) => (e) => {
        const link = document.createElement("a");
        link.href = "/Certificado/download?id=" + i.archivo;
        link.download = i.descripcion + ".ovpn";
        link.target = "_blank";
        link.click();
        link.remove();
    };

    return (
        <Layout alert={alert} confirmAlert={confirmAlert}>
            <SvDrawer>
                <CertForm reload={reload} onAlert={showAlert} />
                <SvClientFilter
                    onChange={setClient}
                    value={client}
                    reload={reload}
                />
            </SvDrawer>
            <SvMain>
                <SvPagination count={count} page={page} onPageChange={setPage}>
                    <SvSearchField reloadFn={reload} />
                </SvPagination>
                <SvTable
                    headers={headers}
                    options={(i) => {
                        return (
                            <Fragment>
                                <PasswordsButton
                                    onClick={(e) =>
                                        history.push("/vpn/passwords/" + i._id + "/" + i.descripcion + "/" + i.ip)
                                    }
                                />
                                <DownloadButton onClick={onDownload(i)} />
                                <DeleteButton
                                    onClick={(e) => {
                                        showConfirmAlert(
                                            "Si continuas se eliminara el certificado " +
                                                i.descripcion,
                                            onDelete(i)
                                        );
                                    }}
                                />
                            </Fragment>
                        );
                    }}
                    prependColumn={(i) => {
                        return <StatusButton isOn={Boolean(i.activo)} />;
                    }}
                    items={items}
                    loading={loadingItems}
                />
            </SvMain>
        </Layout>
    );
}

export default Certs;
