import {
    Grid,
    makeStyles,
    Toolbar
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((t) => ({
	aside: {
		position: 'fixed',
		borderRight: 'solid 1px ' + grey[300],
		backgroundColor: grey[50],
		[t.breakpoints.up('md')]: {
			minHeight: 'calc(100vh - 150px)',
		},
	},
	drawer: {
		width: 350,
		flexShrink: 0,
	},
	drawerPaper: {
		width: 350,
	},
	drawerContainer: {
		overflow: 'auto',
		padding: t.spacing(3),
	},
	content: {
		flexGrow: 1,
		paddingTop: t.spacing(3),
	},
	toolbar: {
		paddingTop: t.spacing(2),
		paddingBottom: t.spacing(2),
	},
}));

export default function SvMain({ children }) {
	const classes = useStyles();

	return (
		<main className={classes.content}>
			<Toolbar className={classes.toolbar} />
			<Grid container>{children}</Grid>
		</main>
	);
}
