import { ThemeProvider } from "@material-ui/styles";
import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { emailTheme } from "../styles/theme";
import EmailHome from "./emailHome";
import Password from "./password";

function EmailHub() {
    const [routes] = useState([
        {
            path: "/password",
            component: <Password />,
        },
        {
            path: "/",
            component: <EmailHome />,
        },
    ]);

    return (
        <ThemeProvider theme={emailTheme}>
            <Switch>
                {routes.map((r) => (
                    <Route path={"/email" + r.path} key={"/email" + r.path}>
                        {r.component}
                    </Route>
                ))}
            </Switch>
        </ThemeProvider>
    );
}

export default EmailHub;
