import {
	Button,
	Grid,
	makeStyles,
	Menu,
	MenuItem,
	TextField
} from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';
import shadows from '../../styles/shadows';
import { onChangeText } from '../../utils/listeners';
import { newCert, selectClients } from '../../utils/requests';
import FileInput from '../fileInput';
import SubmitButton from '../formButton';
import SvSelect from '../select';

const useStyles = makeStyles((t) => ({
	form: {
		paddingTop: t.spacing(2),
		paddingRight: t.spacing(1),
		paddingLeft: t.spacing(1),
	},
	button: {
		boxShadow: shadows.md,
	},
}));

const genDescription = (clientName, ...elems) => {
	let desc = clientName.toUpperCase();

	Object.keys(elems).forEach((k) => {
		if (elems[k] !== '') {
			desc += '_' + elems[k].toUpperCase();
		}
	});

	return desc;
};

function ActualForm({ needsFile, onFinish, onAlert }) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);

	const [client, setClient] = useState(null);
	const [clients, setClients] = useState([]);
	const [product, setProduct] = useState('');
	const [use, setUse] = useState('');
	const [os, setOs] = useState('');
	const [location, setLocation] = useState('');
	const [extras, setExtras] = useState('');
	const [ip, setIp] = useState('');
	const [file, setFile] = useState(null);

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const fd = new FormData();

		fd.append('cliente', client._id);
		fd.append(
			'descripcion',
			genDescription(
				client.name.replace(new RegExp(' '), '_'),
				os,
				product,
				use,
				extras,
				location
			)
		);

		if (needsFile) {
			fd.append('adjunto', file);
			fd.append('ip', ip);
		}

		const r = await newCert(needsFile, fd);

		if (r.Estado === 'ok') {
			onAlert(
				'Certificado ' +
					(needsFile ? 'cargado' : 'creado') +
					' con éxito',
				'success'
			);

			setFile(null);
			setClient(null);
			setProduct('');
			setUse('');
			setOs('');
			setLocation('');
			setExtras('');
			setIp('');

			onFinish(true);
		} else {
			onAlert(
				r.Descripcion
					? r.Descripcion
					: 'Ha ocurrido un error desconocido',
				'error'
			);
		}

		setLoading(false);
	};

	useEffect(() => {
		(async () => {
			const r = await selectClients();

			if (r.Estado === 'ok') {
				setClients(
					r.Registros.map((v) => ({
						label: v.nombre,
						value: { name: v.nombre, _id: v._id },
					}))
				);
			} else {
				onAlert('Ocurrio un error obteniendo los clientes', 'error');
			}
		})();
	}, []);

	return (
		<form onSubmit={onSubmit}>
			<Grid
				direction='column'
				container
				className={classes.form}
				spacing={2}
			>
				<Grid item>
					<SvSelect
						value={client}
						options={clients}
						onChange={onChangeText(setClient)}
						label='Cliente'
						renderValue={(v) => (v ? v.name : 'Sin cliente')}
					/>
				</Grid>
				{[
					['Producto', product, setProduct],
					['SO', os, setOs],
					['Uso', use, setUse],
					['Ubicacion', location, setLocation],
					['Otros', extras, setExtras],
				].map(([label, value, setValue]) => (
					<Grid item key={label}>
						<TextField
							label={label}
							fullWidth
							color='secondary'
							value={value}
							onChange={onChangeText(setValue)}
						/>
					</Grid>
				))}
				{needsFile && (
					<Fragment>
						<Grid item>
							<TextField
								label='IP'
								fullWidth
								color='secondary'
								value={ip}
								onChange={onChangeText(setIp)}
							/>
						</Grid>
						<Grid item>
							<FileInput
								value={file}
								onChange={setFile}
								label='Certificado'
							/>
						</Grid>
					</Fragment>
				)}
				<Grid item>
					<TextField
						label='Descripcion'
						fullWidth
						color='secondary'
						value={genDescription(
							client
								? client.name.replace(new RegExp(' '), '_')
								: 'DESCONOCIDO',
							os,
							product,
							use,
							extras,
							location
						)}
						disabled
						multiline
					/>
				</Grid>
				<Grid item container justifyContent='flex-end'>
					<Button onClick={(e) => onFinish()} disabled={loading}>
						cancelar
					</Button>
					<SubmitButton color='primary' {...{ onSubmit, loading }}>
						crear
					</SubmitButton>
				</Grid>
			</Grid>
		</form>
	);
}

function FormMenu({ onSelect }) {
	const classes = useStyles();
	const [anchor, setAnchor] = useState(null);

	const onClose = (e) => setAnchor(null);
	const onOpen = (e) => setAnchor(e.currentTarget);

	const onSelectItem = (v) => (e) => {
		setAnchor(null);
		onSelect(v);
	};

	return (
		<Fragment>
			<Grid direction='column' container className={classes.form}>
				<Grid item sm={12}>
					<Button
						fullWidth
						color='primary'
						className={classes.button}
						variant='contained'
						disableElevation
						onClick={onOpen}
					>
						Nuevo certificado
					</Button>
				</Grid>
			</Grid>
			<Menu
				keepMounted
				open={Boolean(anchor)}
				anchorEl={anchor}
				onClose={onClose}
			>
				<MenuItem onClick={onSelectItem(0)}>
					Crear certificado nuevo
				</MenuItem>
				<MenuItem onClick={onSelectItem(1)}>Subir certificado</MenuItem>
			</Menu>
		</Fragment>
	);
}

export default function CertForm({ reload, onAlert }) {
	const [mostrar, setMostrar] = useState(-1);

	return (
		<Fragment>
			{mostrar < 0 ? (
				<FormMenu onSelect={setMostrar} />
			) : (
				<ActualForm
					onAlert={onAlert}
					onFinish={(e) => {
						setMostrar(-1);
						if (e) reload();
					}}
					needsFile={mostrar === 1}
				/>
			)}
		</Fragment>
	);
}
