import {
    AppBar,
    Button,
    IconButton,
    makeStyles,
    Toolbar,
    Typography
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import SvLink from "../components/link";
import { logOut } from "../store/slices/sessionSlice";
import shadows from "../styles/shadows";
import { saveCookie } from "../utils/cookie";
import { logout } from "../utils/requests";
import MenuButton from "./menuButton";

const useStyles = makeStyles((t) => ({
    title: {
        flexGrow: 1,
    },
    titleVPN: {
        fontWeight: "bold",
    },
    toolbar: {
        paddingBottom: t.spacing(2),
        paddingTop: t.spacing(2),
    },
    appbar: {
        color: t.palette.common.black,
        backgroundColor: t.palette.appBar.main,
        zIndex: t.zIndex.drawer + 1,
        boxShadow: shadows.md,
    },
}));

function AppBarButton({ content, onClick, active, light, ...props }) {
    const classes = useStyles();

    return (
        <Button
            disableRipple
            color="secondary"
            onClick={onClick}
            disabled={active}
            className={classes.button}
            style={{ fontWeight: light ? "normal" : "bold" }}
            {...props}
        >
            {content}
        </Button>
    );
}

export default function SvAppBar() {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();
    const [, setCookie] = useCookies(["SV_VPNMGR_SESSION"]);

    const onLogout = async (e) => {
        await logout();

        saveCookie({
            setCookie,
            dispatch,
            info: null,
            setValue: logOut,
        });
        window.location.reload();
    };

    return (
        <AppBar className={classes.appbar} position="fixed">
            <Toolbar className={classes.toolbar}>
                <MenuButton />
                <div className={classes.title}>
                    <SvLink to="/vpn/certificados">
                        <Typography
                            color="primary"
                            variant="h4"
                            component="span"
                            className={classes.titleVPN}
                        >
                            vpn
                        </Typography>
                        <Typography variant="h4" component="span">
                            manager
                        </Typography>
                    </SvLink>
                </div>
                <div>
                    {[
                        {
                            to: "/vpn/certificados",
                            label: "certificados",
                        },
                        {
                            to: "/vpn/clientes",
                            label: "clientes",
                        },
                        {
                            to: "/vpn/usuarios",
                            label: "usuarios",
                        },
                    ].map((l) => (
                        <SvLink to={l.to} key={l.to}>
                            <AppBarButton
                                content={l.label}
                                disabled={location.pathname === l.to}
                            />
                        </SvLink>
                    ))}
                    <AppBarButton
                        content="cerrar sesión"
                        light
                        color="primary"
                        onClick={onLogout}
                    />
                </div>
            </Toolbar>
        </AppBar>
    );
}
