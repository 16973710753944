import { useState } from "react";
import { DeleteButton, EditButton } from "../components/buttons";
import AliasForm from "../components/forms/aliases";
import Layout from "../components/layout";
import SvMain from "../components/main";
import SvPagination from "../components/pagination";
import SvTable from "../components/table";
import { SvSearchField } from "../components/textField";
import {
    deleteAlias,
    handleSimpleResponse,
    listAliases,
} from "../utils/requests";
import useSvLayout from "../utils/useLayout";

const headers = [
    { label: "Dominio", key: "DomainName" },
    { label: "Origen", key: "Source" },
    { label: "Destino", key: "Destination" },
];

const listItems = ({ setItems, setCount, page, search }) => {
    return async () => {
        const r = await listAliases({ pagina: page, text: search });

        if (r.Estado === "ok") {
            setItems(r.Registros);
            setCount(r.Cant);
        } else {
            setItems([]);
            setCount(0);
        }
    };
};

function Aliases() {
    const {
        page,
        setPage,
        count,
        items,
        loadingItems,
        reload,
        alert,
        showAlert,
        confirmAlert,
        showConfirmAlert,
    } = useSvLayout(listItems, 30000);
    const [item, setItem] = useState(null);

    const onSubmitted = () => {
        setItem(null);
        reload();
    };

    const onDelete = (i) => async (e) => {
        const r = await deleteAlias(i.AliasId);
        handleSimpleResponse({
            success: "Alias eliminado con exito",
            error: "Hubo un problema eliminando el alias",
            response: r,
            alert: showAlert,
            reload,
            handler: (r) => {
                showConfirmAlert();
            },
        });
    };

    return (
        <Layout alert={alert} confirmAlert={confirmAlert}>
            <SvMain>
                <SvPagination count={count} page={page} onPageChange={setPage}>
                    <SvSearchField reloadFn={reload} />
                    <AliasForm
                        onAlert={showAlert}
                        defaultValue={item}
                        onSubmitted={onSubmitted}
                    />
                </SvPagination>
                <SvTable
                    headers={headers}
                    items={items}
                    loading={loadingItems}
                    options={(i) => {
                        return (
                            <>
                                <EditButton onClick={(_) => setItem(i)} />
                                <DeleteButton
                                    onClick={(e) => {
                                        showConfirmAlert(
                                            "Si continuas se eliminara el alias de " +
                                                i.Source,
                                            onDelete(i)
                                        );
                                    }}
                                />
                            </>
                        );
                    }}
                />
            </SvMain>
        </Layout>
    );
}

export default Aliases;
