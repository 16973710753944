import {
	Tooltip,
	withStyles
} from '@material-ui/core';
import shadows from '../styles/shadows';

export default withStyles((t) => ({
	tooltip: {
		backgroundColor: t.palette.common.white,
		color: t.palette.primary.main,
		boxShadow: shadows.md,
		fontWeight: 'bold',
	},
}))(Tooltip);