import SvDrawer from '../components/drawer';
import ClientForm from '../components/forms/clients';
import Layout from '../components/layout';
import SvMain from '../components/main';
import SvPagination from '../components/pagination';
import SvTable from '../components/table';
import { SvSearchField } from '../components/textField';
import { listClients } from '../utils/requests';
import useSvLayout from '../utils/useLayout';

const headers = [{ label: 'Nombre', key: 'nombre' }];

const listItems = ({ setItems, setCount, page, search }) => {
	return async () => {
		const r = await listClients(page, search);

		if (r.Estado === 'ok') {
			setItems(r.Registros);
			setCount(r.Cuenta);
		} else {
			setItems([]);
			setCount(0);
		}
	};
};

function Clients() {
	const {
		page,
		setPage,
		count,
		items,
		loadingItems,
		reload,
		alert,
		showAlert,
	} = useSvLayout(listItems);

	return (
		<Layout alert={alert}>
			<SvDrawer>
				<ClientForm reload={reload} onAlert={showAlert} />
			</SvDrawer>
			<SvMain>
				<SvPagination count={count} page={page} onPageChange={setPage}>
					<SvSearchField reloadFn={reload} />
				</SvPagination>
				<SvTable
					headers={headers}
					items={items}
					loading={loadingItems}
				/>
			</SvMain>
		</Layout>
	);
}

export default Clients;
