import axios from "axios";
import JQuery from "jquery";

const postRequest = async (url, body) => {
    return await axios
        .post(url, body)
        .then((r) => {
            return r.data;
        })
        .catch((e) => {
            if (e.response) {
                return e.response.data;
            }
            return { Estado: "error" };
        });
};

const getRequest = async (url, body) => {
    return await axios
        .get(url + "?" + JQuery.param(body))
        .then((r) => {
            return r.data;
        })
        .catch((e) => {
            if (e.response) {
                return e.response.data;
            }
            return { Estado: "error" };
        });
};

export async function handleSimpleResponse({
    response: r,
    alert,
    reload,
    success,
    error,
    handler,
}) {
    if (r.Estado === "ok") {
        if (alert && success) alert(success, "success");
        if (handler) handler(r);
        if (reload) reload();
    } else {
        if (alert) alert(r.Descripcion ? r.Descripcion : error, "error");
    }
}

export async function login(body) {
    const d = await postRequest("/Login/login", body);
    if (d.Estado === "ok" || d.Estado === "sesion iniciada") {
        return d.data;
    }
    return null;
}

export async function logout() {
    return await postRequest("/Login/logout", {});
}

export async function newClient(nombre) {
    return await postRequest("/Cliente/register", { nombre });
}

export async function listClients(pagina, texto) {
    return await postRequest("/Cliente/list", { pagina, texto });
}

export async function selectClients() {
    return await postRequest("/Cliente/select", {});
}

export async function newUser(usuario, nombre, password) {
    return await postRequest("/Usuario/register", {
        nombre,
        usuario,
        password,
    });
}

export async function disableUser(usuario, id) {
    return await postRequest("/Usuario/disable", { usuario, id });
}

export async function deleteUser(usuario, id) {
    return await postRequest("/Usuario/delete", { usuario, id });
}

export async function listUsers(pagina, texto) {
    return await postRequest("/Usuario/list", { pagina, texto });
}

export async function newCert(file, fd) {
    return await postRequest(
        "/Certificado/" + (file ? "upload" : "register"),
        fd
    );
}

export async function deleteCert(id) {
    return await postRequest("/Certificado/delete", { id });
}

export async function listCerts(pagina, texto, cliente) {
    return await postRequest("/Certificado/list", { pagina, texto, cliente });
}

// Peticiones para las passwords
export async function newPassword(servicio, usuario, password, certificado) {
    return await postRequest("/Password/register", {
        servicio,
        usuario,
        password,
        certificado
    });
}

export async function editPassword(servicio, usuario, password, id, certificado) {
    return await postRequest("/Password/update", {
        servicio,
        usuario,
        password,
        id,
        certificado
    });
}

export async function listPasswords(pagina, texto, certificado) {
    return await postRequest("/Password/list", { pagina, texto, certificado });
}

export async function deletePassword(id) {
    return await postRequest("/Password/delete", { id });
}

// Peticiones sobre los correos

export async function listMails({ pagina, text }) {
    return await getRequest("/Mail/listarCorreos", { pagina, text });
}

export async function listDomains({ pagina, text }) {
    return await getRequest("/Mail/listarDomain", { pagina, text });
}

export async function listAliases({ pagina, text }) {
    return await getRequest("/Mail/listarAlias", { pagina, text });
}

export async function selectMails() {
    return await getRequest("/Mail/selectCorreos", {});
}

export async function selectDomains() {
    return await getRequest("/Mail/selectDomain", {});
}

export async function selectAliases() {
    return await getRequest("/Mail/selectAlias", {});
}

export async function addMail({ DomainId, password, Email }) {
    return await postRequest("/Mail/agregarCorreo", {
        DomainId,
        password,
        Email,
    });
}

export async function deleteMail(AccountId) {
    return await postRequest("/Mail/eliminarCorreo", { AccountId });
}

export async function updatePassword(AccountId, password) {
    return await postRequest("/Mail/actualizarPassword", {
        AccountId,
        password,
    });
}

export async function addDomain(DomainName) {
    return await postRequest("/Mail/agregarDominio", { DomainName });
}

export async function updateDomain(DomainId, DomainName) {
    return await postRequest("/Mail/actualizarDominio", {
        DomainName,
        DomainId,
    });
}

export async function deleteDomain(DomainId) {
    return await postRequest("/Mail/eliminarDominio", { DomainId });
}

export async function addAlias({ DomainId, Source, Destination }) {
    return await postRequest("/Mail/agregarAlias", {
        DomainId,
        Source,
        Destination,
    });
}

export async function updateAlias({ AliasId, DomainId, Source, Destination }) {
    return await postRequest("/Mail/actualizarAlias", {
        AliasId,
        DomainId,
        Source,
        Destination,
    });
}

export async function deleteAlias(AliasId) {
    return await postRequest("/Mail/eliminarAlias", { AliasId });
}

export async function changePassword(
    correo,
    contraseniaActual,
    contraseniaNueva
) {
    return await postRequest("/Mail/cambiarPassword", {
        correo,
        contraseniaActual,
        contraseniaNueva,
    });
}
