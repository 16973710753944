import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
    DeleteButton,
    DownloadButton,
    EditButton
} from "../components/buttons";
import SvDrawer from "../components/drawer";
import ClientForm from "../components/forms/clients";
import PasswordsForm from "../components/forms/passwords";
import Layout from "../components/layout";
import SvMain from "../components/main";
import SvPagination from "../components/pagination";
import SvTable from "../components/table";
import { SvSearchField } from "../components/textField";
import { deletePassword, listPasswords } from "../utils/requests";
import useSvLayout from "../utils/useLayout";

const headers = [
    { label: "Servicio", key: "servicio" },
    { label: "Usuario", key: "usuario" },
    { label: "Contraseña", key: "password" },
];

const listItems = ({ setItems, setCount, page, search, certificado }) => {
    return async () => {
        const r = await listPasswords(page, search, certificado);

        if (r.Estado === "ok") {
            setItems(r.Registros);
            setCount(r.Cuenta);
        } else {
            setItems([]);
            setCount(0);
        }
    };
};

const useStyles = makeStyles((t) => ({
    mainElem: {
        padding: t.spacing(2.4),
    },
}));

function Passwords() {
    const classes = useStyles();
    const { certificado, descripcion, ip } = useParams();
    const [actualPassword, setActualPassword] = useState(null);
    const {
        page,
        setPage,
        count,
        items,
        loadingItems,
        reload,
        alert,
        showAlert,
        confirmAlert,
        showConfirmAlert,
    } = useSvLayout((props) => listItems({ ...props, certificado }));

    const onEdit = (i) => (e) => setActualPassword(i);
    const onEdited = () => {
        setActualPassword(null);
        reload();
    };

    const onDelete = (i) => async (e) => {
        const r = await deletePassword(i._id);
        showConfirmAlert();
        if (r.Estado === "ok") {
            showAlert("Contraseña eliminada con éxito", "success");
            reload();
        } else {
            showAlert(
                r.Descripcion
                    ? r.Descripcion
                    : "Ha ocurrido un error desconocido",
                "error"
            );
        }
    };

    return (
        <Layout alert={alert} confirmAlert={confirmAlert}>
            <SvDrawer>
                <PasswordsForm
                    certificado={certificado}
                    reload={reload}
                    onAlert={showAlert}
                    onEdited={onEdited}
                    valueEdit={actualPassword}
                />
            </SvDrawer>
            <SvMain>
                <Grid
                    className={classes.mainElem}
                    direction="column"
                    container
                >
                    <Grid item>
                        <Typography variant="h6" color="secondary">
                            {ip} {descripcion}
                        </Typography>
                    </Grid>
                </Grid>
                <SvPagination count={count} page={page} onPageChange={setPage}>
                    <SvSearchField reloadFn={reload} />
                </SvPagination>
                <SvTable
                    headers={headers}
                    items={items}
                    loading={loadingItems}
                    options={(i) => {
                        return (
                            <>
                                <EditButton onClick={onEdit(i)} />
                                <DeleteButton
                                    onClick={(e) => {
                                        showConfirmAlert(
                                            "Si continuas se eliminara la contraseña del servicio " +
                                                i.servicio,
                                            onDelete(i)
                                        );
                                    }}
                                />
                            </>
                        );
                    }}
                />
            </SvMain>
        </Layout>
    );
}

export default Passwords;
