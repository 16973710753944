import {
    Drawer,
    makeStyles,
    Toolbar
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((t) => ({
	drawer: {
		width: 350,
		flexShrink: 0,
	},
	drawerPaper: {
		width: 350,
        backgroundColor: grey[50],
	},
	drawerContainer: {
		overflow: 'auto',
		padding: t.spacing(3),
	},
	toolbar: {
		paddingTop: t.spacing(2),
		paddingBottom: t.spacing(2),
	},
}));

export default function SvDrawer({ children }) {
	const classes = useStyles();

	return (
		<Drawer
			variant='permanent'
			className={classes.drawer}
			classes={{ paper: classes.drawerPaper }}
		>
			<Toolbar className={classes.toolbar} />
			<div className={classes.drawerContainer}>{children}</div>
		</Drawer>
	);
}
