import { Button, Grid, TextField } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { onChangeText } from "../../utils/listeners";
import {
    addAlias,
    handleSimpleResponse,
    selectDomains,
    updateAlias
} from "../../utils/requests";
import { FormDialog } from "../dialog";
import SvSelect from "../select";

export default function AliasForm({ onAlert, defaultValue, onSubmitted }) {
    const [open, setOpen] = useState(false);
    const [domains, setDomains] = useState([]);
    const [domainId, setDomainId] = useState("");
    const [source, setSource] = useState("");
    const [destination, setDestination] = useState("");
    const [aliasId, setAliasId] = useState("");

    const openModal = () => setOpen(true);
    const closeModal = () => {
        setOpen(false);
        onSubmitted();
    };

    const handleBlur = (value, setter) => (e) => {
        if (value !== "" && value.indexOf("@") === -1) {
            setter(value + "@sisvox.com.mx");
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const r = defaultValue
            ? await updateAlias({
                  AliasId: aliasId,
                  DomainId: domainId,
                  Source: source,
                  Destination: destination,
              })
            : await addAlias({
                  DomainId: domainId,
                  Source: source,
                  Destination: destination,
              });

        handleSimpleResponse({
            response: r,
            alert: onAlert,
            success: defaultValue
                ? "Alias editado con exito"
                : "Alias creado con exito",
            error: "Ocurrio un error haciendo la peticion",
            handler: (r) => {
                setDomainId("");
                setSource("");
                setDestination("");
                setAliasId("");

                closeModal();
            },
        });
    };

    useEffect(() => {
        (async () => {
            const rdomains = await selectDomains();
            handleSimpleResponse({
                response: rdomains,
                error: "Hubo un error consultando los dominios",
                alert: onAlert,
                handler: (r) => {
                    setDomains(
                        r.Registros.map((v) => ({
                            label: v.DomainName,
                            value: v.DomainId,
                        }))
                    );
                },
            });
        })();

        if (defaultValue) {
            setSource(defaultValue.Source);
            setDestination(defaultValue.Destination);
            setDomainId(defaultValue.DomainId);
            setAliasId(defaultValue.AliasId);
            openModal();
        }
    }, [defaultValue]);

    return (
        <Fragment>
            {!defaultValue && (
                <Button
                    style={{ marginLeft: 8 }}
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={openModal}
                >
                    nuevo alias
                </Button>
            )}
            <FormDialog
                open={open}
                title={defaultValue ? "Editar alias" : "Nuevo alias"}
                onClose={closeModal}
                onSubmit={onSubmit}
            >
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <SvSelect
                            label="Dominio"
                            value={domainId}
                            options={domains}
                            onChange={onChangeText(setDomainId)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Origen"
                            value={source}
                            color="primary"
                            onChange={onChangeText(setSource)}
                            onBlur={handleBlur(source, setSource)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Destino"
                            value={destination}
                            color="primary"
                            onChange={onChangeText(setDestination)}
                            onBlur={handleBlur(destination, setDestination)}
                        />
                    </Grid>
                </Grid>
            </FormDialog>
        </Fragment>
    );
}
