import {
    blueGrey,
    brown,
    deepOrange,
    green,
    grey,
    teal
} from '@material-ui/core/colors';
import { createTheme, lighten } from '@material-ui/core/styles';
import shadows from './shadows';

const whiteTheme = createTheme({
	palette: {
		primary: {
			main: '#FFFFFF',
		},
		secondary: {
			main: deepOrange[500],
			contrastText: '#FFFFFF',
		},
		background: '#FFFFFF',
	},
	typography: {
		fontFamily: ['Kumbh Sans', 'Arial'].join(','),
		button: {
			textTransform: 'none',
		},
	},
	overrides: {
		MuiBackdrop: {
			root: {
				backgroundColor: 'rgba(255, 255, 255,0.85)',
			},
		},
		MuiDialog: {
			paper: {
				boxShadow: shadows.xl,
				background: '#FFFFFF',
				minWidth: '20vw',
			},
		},
		MuiMenu: {
			paper: {
				boxShadow: shadows.lg,
				background: '#FFFFFF',
			},
		},
	},
});

const emailTheme = createTheme({
	palette: {
		primary: {
			main: green[700],
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: teal[600],
			contrastText: '#FFFFFF',
		},
		background: {
			default: '#FFFFFF',
		},
		tableHover: {
			main: blueGrey[50],
		},
        appBar: {
            main: blueGrey[50]
        }
	},
	typography: {
		fontFamily: ['Kumbh Sans', 'Arial'].join(','),
		button: {
			textTransform: 'lowercase',
		},
	},
	overrides: {
		MuiBackdrop: {
			root: {
				backgroundColor: 'rgba(255, 255, 255,0.85)',
			},
		},
		MuiDialog: {
			paper: {
				boxShadow: shadows.sm,
				background: '#FFFFFF',
				border: 'solid 1px',
				borderColor: lighten(grey[500], 0.7),
				minWidth: '20vw',
			},
		},
		MuiMenu: {
			paper: {
				boxShadow: shadows.lg,
				background: '#FFFFFF',
			},
		},
	},
});

export default createTheme({
	palette: {
		primary: {
			main: deepOrange[500],
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: brown[600],
			contrastText: '#FFFFFF',
		},
		background: {
			default: '#FFFFFF',
		},
		tableHover: {
			main: grey[100],
		},
        appBar: {
            main: grey[100]
        }
	},
	typography: {
		fontFamily: ['Kumbh Sans', 'Arial'].join(','),
		button: {
			textTransform: 'lowercase',
		},
	},
	overrides: {
		MuiBackdrop: {
			root: {
				backgroundColor: 'rgba(255, 255, 255,0.85)',
			},
		},
		MuiDialog: {
			paper: {
				boxShadow: shadows.sm,
				background: '#FFFFFF',
				border: 'solid 1px',
				borderColor: lighten(grey[500], 0.7),
				minWidth: '20vw',
			},
		},
		MuiMenu: {
			paper: {
				boxShadow: shadows.lg,
				background: '#FFFFFF',
			},
		},
	},
});

export { whiteTheme, emailTheme };

