import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    createTheme,
    Grid,
    makeStyles,
    TextField,
    ThemeProvider,
    Typography
} from "@material-ui/core";
import { blueGrey, red } from "@material-ui/core/colors";
import { useState } from "react";
import { Alert } from "../components/alerts";
import PasswordField from "../components/passwordField";
import bg from "../img/emails/bg3.svg";
import { changePassword, handleSimpleResponse } from "../utils/requests";

const theme = createTheme({
    palette: {
        primary: {
            main: red[500],
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: blueGrey[600],
            contrastText: "#FFFFFF",
        },
        background: {
            // default: colors.blueGrey[800],
        },
    },
    typography: {
        fontFamily: ["Kumbh Sans", "Arial"].join(","),
        button: {
            textTransform: "lowercase",
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100vw",
        height: "100vh",
        overflowY: "auto",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
    },
    img: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: -1,
        opacity: 0.2,
    },
    card: {
        margin: "auto",
        background: "white",
        boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
        minWidth: "80vw",
        [theme.breakpoints.up("sm")]: {
            minWidth: "60vw",
        },
        [theme.breakpoints.up("md")]: {
            minWidth: "30vw",
        },
        [theme.breakpoints.up("lg")]: {
            minWidth: "20vw",
        },
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1),
    },
    vpn: {
        fontWeight: "bolder",
    },
    spacer: {
        flexGrow: 1,
    },
    button: {
        fontWeight: "bold",
    },
    title: {
        textAlign: "center",
    },
}));

export default function Password() {
    const classes = useStyles();

    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const showAlert = (content, variant) => {
        if (content) {
            setAlert({
                content,
                variant,
                onClose: (e) => setAlert(null),
            });
        } else {
            setAlert(null);
        }
    };

    const validate = () =>
        email !== "" &&
        password !== "" &&
        newPassword !== "" &&
        confirmPassword === newPassword;

    const handleChange = (setter) => (e) => setter(e.target.value);
    const handleBlur = (e) => {
        if (email !== "" && email.indexOf("@") === -1) {
            setEmail(email + "@sisvox.com.mx");
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        const r = await changePassword(email, password, newPassword);

        handleSimpleResponse({
            response: r,
            alert: showAlert,
            success: "Contraseña cambiada, redirigiendo a mail.sisvox.com.mx",
            error: "Ocurrio un error haciendo la peticion",
            handler: (r) => {
                setEmail("");
                setPassword("");
                setNewPassword("");
                setConfirmPassword("");

                setTimeout(() => {
                    window.location.href = "https://mail.sisvox.com.mx";
                }, 1500);
            },
        });

        setLoading(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <img src={bg} className={classes.img} alt="bg" />
            <div className={classes.root}>
                <Card className={classes.card}>
                    <form onSubmit={onSubmit}>
                        <CardContent className={classes.title}>
                            <Typography
                                variant="h5"
                                component="span"
                                className={classes.vpn}
                            >
                                Sisvox
                            </Typography>
                            <Typography
                                variant="h5"
                                color="primary"
                                component="span"
                                className={classes.vpn}
                            >
                                Soft
                            </Typography>
                        </CardContent>
                        <CardContent>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <TextField
                                        label="correo"
                                        color="secondary"
                                        fullWidth
                                        value={email}
                                        onChange={handleChange(setEmail)}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item>
                                    <PasswordField
                                        label="contraseña actual"
                                        color="secondary"
                                        fullWidth
                                        value={password}
                                        onChange={handleChange(setPassword)}
                                    />
                                </Grid>
                                <Grid item>
                                    <PasswordField
                                        label="contraseña nueva"
                                        color="secondary"
                                        fullWidth
                                        value={newPassword}
                                        onChange={handleChange(setNewPassword)}
                                    />
                                </Grid>
                                <Grid item>
                                    <PasswordField
                                        label="confirmar contraseña"
                                        color={
                                            confirmPassword !== newPassword
                                                ? "primary"
                                                : "secondary"
                                        }
                                        fullWidth
                                        value={confirmPassword}
                                        onChange={handleChange(
                                            setConfirmPassword
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <div className={classes.spacer}></div>
                            <Button
                                color="primary"
                                type="submit"
                                className={classes.button}
                                disabled={!validate() || loading}
                                endIcon={
                                    loading && (
                                        <CircularProgress
                                            size={20}
                                            color="primary"
                                        />
                                    )
                                }
                            >
                                cambiar contraseña
                            </Button>
                        </CardActions>
                    </form>
                </Card>
                {alert && <Alert {...alert} />}
            </div>
        </ThemeProvider>
    );
}
